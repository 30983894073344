import { ReactElement } from 'react'
import { CircularProgress, Backdrop, Grid } from '@mui/material'

const Loader = (): ReactElement => (
	<Grid container>
		<Backdrop sx={{ color: 'secondary.main' }} open={true}>
			<CircularProgress sx={{ color: 'common.black' }} />
		</Backdrop>
	</Grid>
)

export default Loader
