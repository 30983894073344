import { Snackbar, Alert } from '@mui/material'
import { handleNotification } from 'context/actions'
import { useAppContext } from 'hooks'
import { ReactElement } from 'react'

const Notification = (): ReactElement => {
	const {
		state: {
			notification: { open, variant, message },
		},
		dispatch,
	} = useAppContext()

	const handleClose = () => dispatch(handleNotification(false, 'info', ''))

	return (
		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
			open={open}
			autoHideDuration={5000}
			sx={{ width: '100%', maxWidth: '500px' }}
			onClose={handleClose}
		>
			<Alert onClose={handleClose} severity={variant} sx={{ width: '100%' }}>
				{message}
			</Alert>
		</Snackbar>
	)
}

export default Notification
