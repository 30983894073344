import { Editor } from '@tinymce/tinymce-react'
import { Grid } from '@mui/material'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const TinyEditor = ({ value, handleEditorChange }) => {
	const editorInit = {
		height: '500px',
		plugins: [
			'advlist autolink lists link image charmap print preview anchor',
			'searchreplace visualblocks code fullscreen',
			'insertdatetime media table paste code help wordcount',
		],
		toolbar:
			'undo redo | formatselect | bold italic backcolor | \
			alignleft aligncenter alignright alignjustify | \
			bullist numlist outdent indent | removeformat | help',
		file_picker_types: 'image',
		file_picker_callback: function (cb) {
			// eslint-disable-next-line no-undef
			const input = document.createElement('input')
			input.setAttribute('type', 'file')
			input.setAttribute('accept', 'image/*')
			input.onchange = function () {
				const file = this.files[0]
				// eslint-disable-next-line no-undef
				const reader = new FileReader()
				reader.onload = function () {
					const id = 'blobid' + (new Date()).getTime()
					// eslint-disable-next-line no-undef
					const blobCache = tinymce.activeEditor.editorUpload.blobCache
					const base64 = reader.result.split(',')[1]
					const blobInfo = blobCache.create(id, file, base64)
					blobCache.add(blobInfo)
					cb(blobInfo.blobUri(), { title: file.name })
				}
				reader.readAsDataURL(file)
			}
			input.click()
		},
	}

	return (
		<Grid
			container
			justifyContent='center'
			sx={{
				mb: '20px',
			}}
		>
			<Editor
				apiKey={process.env.REACT_APP_TINY_API_KEY}
				value={value}
				onEditorChange={handleEditorChange}
				init={editorInit}
			/>
		</Grid>
	)
}

export default TinyEditor
