import { ReactElement, useMemo } from 'react'
import { useMediaQuery, ThemeProvider, CssBaseline } from '@mui/material'
import { Notification } from 'components'
import { useAppContext } from 'hooks'
import createTheme from './theme'

interface IProps {
	children: ReactElement
}

const Theme = ({ children }: IProps): ReactElement => {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
	const {
		state: { themeMode },
	} = useAppContext()

	const themeConfig = useMemo(() => {
		if (themeMode) return createTheme(themeMode)

		return createTheme(prefersDarkMode ? 'dark' : 'light')
	}, [prefersDarkMode, themeMode])

	return (
		<ThemeProvider theme={themeConfig}>
			<CssBaseline />
			<Notification />
			{children}
		</ThemeProvider>
	)
}

export default Theme
