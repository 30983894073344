import { createContext, useReducer, ReactElement } from 'react'
import reducer from './reducer'
import { IAppContext, IAppState } from './types'

const initialState: IAppState = {
	notification: {
		open: false,
		variant: 'info',
		message: '',
	},
	themeMode: 'light',
	tableOptions: {
		category: 'article',
		rowsPerPage: 5,
		page: 0,
		search: '',
	},
	updateMode: {
		slug: '',
		category: 'article',
	},
	createMode: {
		active: false,
		category: 'article',
	},
	modal: {
		open: false,
		id: '',
		category: 'article',
	},
}

const CustomContext = createContext<IAppContext>({
	state: initialState,
	dispatch: () => null,
})

const ContextProvider = ({
	children,
}: {
	children: ReactElement
}): ReactElement => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return (
		<CustomContext.Provider value={{ state, dispatch }}>
			{children}
		</CustomContext.Provider>
	)
}

export { ContextProvider, CustomContext }
