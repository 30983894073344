/* eslint-disable indent */
import { ContextActionEnums, IAppState, ContextActions } from './types'

const reducer = (state: IAppState, action: ContextActions): IAppState => {
	switch (action.type) {
		case ContextActionEnums.HANDLE_NOTIFICATION: {
			return {
				...state,
				notification: action.payload,
			}
		}
		case ContextActionEnums.HANDLE_THEME: {
			return {
				...state,
				themeMode: action.payload,
			}
		}
		case ContextActionEnums.HANDLE_TABLE_OPTIONS: {
			return {
				...state,
				tableOptions: action.payload,
			}
		}
		case ContextActionEnums.HANDLE_MODAL: {
			return {
				...state,
				modal: action.payload,
			}
		}
		case ContextActionEnums.HANDLE_UPDATE_MODE: {
			return {
				...state,
				updateMode: action.payload,
			}
		}
		case ContextActionEnums.HANDLE_CREATE_MODE: {
			return {
				...state,
				createMode: action.payload,
			}
		}
		default:
			return state
	}
}

export default reducer
