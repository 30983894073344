import { ReactElement, useState } from 'react'
import {
	Box,
	Backdrop,
	SpeedDial,
	SpeedDialAction,
	SpeedDialIcon,
} from '@mui/material'
import {
	ArticleRounded,
	ContentPasteGoRounded,
	ContentPasteRounded,
	SummarizeRounded,
} from '@mui/icons-material'
import { useAppContext } from 'hooks'
import { handleCreateMode } from 'context/actions'

interface IOptions {
	icon: ReactElement
	name: string
	value: 'article' | 'our' | 'public' | 'repository'
}

const categoryOptions: IOptions[] = [
	{
		icon: <ArticleRounded />,
		name: 'Блог',
		value: 'article',
	},
	{
		icon: <ContentPasteGoRounded />,
		name: 'Наші послуги',
		value: 'our',
	},
	{
		icon: <ContentPasteRounded />,
		name: 'Публічні послуги',
		value: 'public',
	},
	{
		icon: <SummarizeRounded />,
		name: 'Репозиторій',
		value: 'repository',
	},
]

const CreateButton = (): ReactElement => {
	const { dispatch } = useAppContext()
	const [open, setOpen] = useState(false)

	const handleHover = () => setOpen(prev => !prev)

	const handleCreate = (category: IOptions['value']) =>
		dispatch(handleCreateMode(true, category))

	return (
		<Box sx={{ width: '56px' }}>
			<Backdrop open={open} />
			<SpeedDial
				ariaLabel='SpeedDial tooltip'
				sx={{ position: 'absolute' }}
				icon={<SpeedDialIcon />}
				onClose={handleHover}
				onOpen={handleHover}
				open={open}
				direction='down'
			>
				{categoryOptions.map(category => (
					<SpeedDialAction
						key={category.name}
						icon={category.icon}
						tooltipTitle={category.name}
						tooltipOpen
						onClick={() => handleCreate(category.value)}
					/>
				))}
			</SpeedDial>
		</Box>
	)
}

export default CreateButton
