import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	IconButton,
} from '@mui/material'
import { ReactElement, useCallback } from 'react'
import { ICreateRepository } from 'types'
import { EditRounded, DeleteRounded } from '@mui/icons-material'
import { useAppContext } from 'hooks'
import { handleModal, handleUpdateMode } from 'context/actions'

interface IProps {
	rows: ICreateRepository[]
}

const header = ['#', 'Назва', 'Посилання', 'Рік', '', '']

const List = ({ rows }: IProps): ReactElement => {
	const {
		state: {
			tableOptions: { category, rowsPerPage, page },
		},
		dispatch,
	} = useAppContext()

	const handleUpdate = useCallback(
		(slug: string) => {
			dispatch(handleUpdateMode(slug, category))
		},
		[dispatch, category]
	)

	const handleDelete = useCallback(
		(id: string) => {
			dispatch(handleModal({ open: true, id, category }))
		},
		[dispatch, category]
	)

	return (
		<TableContainer component='div'>
			<Table sx={{ minWidth: 650 }} aria-label='simple table'>
				<TableHead>
					<TableRow>
						{header.map((name, index) => (
							<TableCell align='left' key={index}>
								{name}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row, index) => (
						<TableRow
							key={row?._id}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<TableCell component='th' scope='row'>
								{rowsPerPage * page + index + 1}
							</TableCell>
							<TableCell>{row?.name || ''}</TableCell>
							<TableCell>{row?.link || ''}</TableCell>
							<TableCell>{row?.year || ''}</TableCell>
							<TableCell>
								<IconButton
									onClick={() => handleUpdate(row?._id)}
									aria-label='edit'
								>
									<EditRounded />
								</IconButton>
							</TableCell>
							<TableCell>
								<IconButton
									onClick={() => handleDelete(row?._id)}
									aria-label='remove'
								>
									<DeleteRounded />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default List
