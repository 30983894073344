import { ReactElement, useMemo, useEffect } from 'react'
import { Container, Grid, Button } from '@mui/material'
import Cookies from 'js-cookie'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { LogoIcon } from 'components'

const Header = (): ReactElement => {
	const navigate = useNavigate()
	const { pathname } = useLocation()

	const isAuth = useMemo(
		() => pathname === '/dashboard' && !!Cookies.get('authToken'),
		[pathname]
	)

	const handleLogout = () => {
		Cookies.remove('authToken')
		navigate('/')
	}

	useEffect(() => {
		isAuth && navigate('/dashboard')
	}, [isAuth])

	return (
		<Container sx={{ pt: '24px', pb: '24px' }}>
			<Grid
				container
				position='relative'
				alignItems='center'
				justifyContent='center'
			>
				<Link to='/'>
					<LogoIcon />
				</Link>
				<Button
					sx={{
						position: 'absolute',
						right: '0',
						bgcolor: 'secondary.main',
						height: 'fit-content',
						visibility: isAuth ? 'visible' : 'hidden',
					}}
					onClick={handleLogout}
				>
					Вийти
				</Button>
			</Grid>
		</Container>
	)
}

export default Header
