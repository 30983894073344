/* eslint-disable indent */
import { ReactElement, useCallback } from 'react'
import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material'
import { useAppContext } from 'hooks'
import { handleModal, handleNotification } from 'context/actions'
import { deleteOne } from 'api'

const Modal = (): ReactElement => {
	const {
		state: {
			modal: { open, id, category },
		},
		dispatch,
	} = useAppContext()

	const handleClose = useCallback(() => {
		dispatch(handleModal({ open: false, id: '', category: 'article' }))
	}, [dispatch])

	const handleDelete = useCallback(async () => {
		const response = await deleteOne(
			category === 'article'
				? 'article'
				: category === 'repository'
				? 'repository'
				: 'service',
			id
		)
		if (response?.status === 200) {
			handleNotification(true, 'success', 'Видалено')
			handleClose()
		} else {
			handleNotification(false, 'error', response.message)
			handleClose()
		}
	}, [id, category])

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle id='alert-dialog-title' sx={{ p: '30px' }}>
				Ви точно хочете видалити?
			</DialogTitle>
			<DialogActions sx={{ p: '30px' }}>
				<Button color='info' onClick={handleClose}>
					Відмінити
				</Button>
				<Button color='info' onClick={handleDelete} autoFocus>
					Так
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default Modal
