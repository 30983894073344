import { ReactElement } from 'react'
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from 'react-router-dom'
import { Home, Dashboard } from 'pages'
import { Header } from 'components'
import Cookies from 'js-cookie'

const App = (): ReactElement => {
	const PrivateRoute = ({ children }: { children: ReactElement }) =>
		Cookies.get('authToken') ? children : <Navigate to='/' />

	return (
		<div className='App'>
			<Router>
				<Header />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route
						path='/dashboard'
						element={
							<PrivateRoute>
								<Dashboard />
							</PrivateRoute>
						}
					></Route>
				</Routes>
			</Router>
		</div>
	)
}

export default App
