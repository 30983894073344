import { ReactElement, Fragment, useState } from 'react'
import { Collapse, Paper } from '@mui/material'
import { useAppContext } from 'hooks'
import { ICreateData } from 'types'
import { DataForm } from 'components'
import Form from 'components/Repository/components/Form'

const CreateSection = (): ReactElement => {
	const [data, setData] = useState<ICreateData>({
		title: '',
		description: '',
		content: '',
		tags: [''],
		words: 0,
	})
	const {
		state: {
			createMode: { active, category },
		},
	} = useAppContext()

	return (
		<Fragment>
			<Collapse in={active} timeout='auto' unmountOnExit>
				<Paper sx={{ p: '24px' }}>
					{category !== 'repository' ? (
						<DataForm data={data} setData={setData} />
					) : (
						<Form
							data={{
								name: '',
								category: 'history',
								year: 0,
								link: '',
							}}
						/>
					)}
				</Paper>
			</Collapse>
		</Fragment>
	)
}

export default CreateSection
