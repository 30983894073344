import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { Paper, Box, Grid, Button } from '@mui/material'
import { Input } from 'components'
import { useAppContext } from 'hooks'
import { handleNotification } from 'context/actions'
import * as yup from 'yup'
import { createOne } from 'api'
import Cookies from 'js-cookie'

const validationSchema = yup.object({
	email: yup
		.string()
		.email('Введіть коректну пошту')
		.required("Поле обов'язкове"),
	password: yup.string().required("Поле обов'язкове"),
})

const Login = (): ReactElement => {
	const navigate = useNavigate()
	const { dispatch } = useAppContext()

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema,
		onSubmit: async ({ email, password }) => {
			const response = await createOne(
				'login',
				JSON.stringify({
					email,
					password,
				})
			)

			if (response?.status === 200) {
				dispatch(handleNotification(true, 'success', 'Авторизовано'))
				Cookies.set('authToken', response?.data?.token || '')
				navigate('/dashboard')
			} else {
				dispatch(handleNotification(true, 'error', response?.message))
			}
		},
	})

	return (
		<Paper sx={{ width: 'fit-content', p: '40px' }}>
			<Box component='form' onSubmit={formik.handleSubmit}>
				<Grid
					container
					height='220px'
					justifyContent='space-between'
					flexDirection='column'
				>
					<Input
						name='email'
						label='Пошта'
						value={formik?.values?.email}
						onChange={formik.handleChange}
						error={formik?.touched?.email && Boolean(formik.errors.email)}
						helperText={formik?.touched?.email && formik?.errors?.email}
					/>
					<Input
						name='password'
						type='password'
						label='Пароль'
						value={formik?.values?.password}
						onChange={formik.handleChange}
						error={formik?.touched?.password && Boolean(formik.errors.password)}
						helperText={formik?.touched?.password && formik?.errors?.password}
					/>
					<Button type='submit'>Ввійти</Button>
				</Grid>
			</Box>
		</Paper>
	)
}

export default Login
