import {
	INotification,
	ContextActionEnums,
	ContextActions,
	ThemeType,
	ITableOptions,
	IModal,
	ICreateMode,
	IUpdateMode,
} from './types'

export const handleNotification = (
	open: INotification['open'],
	variant: INotification['variant'],
	message: INotification['message']
): ContextActions => ({
	type: ContextActionEnums.HANDLE_NOTIFICATION,
	payload: {
		open,
		variant,
		message,
	},
})

export const handleTheme = (themeMode: ThemeType): ContextActions => ({
	type: ContextActionEnums.HANDLE_THEME,
	payload: themeMode,
})

export const handleOptions = (options: ITableOptions): ContextActions => ({
	type: ContextActionEnums.HANDLE_TABLE_OPTIONS,
	payload: options,
})

export const handleUpdateMode = (
	slug: IUpdateMode['slug'],
	category: IUpdateMode['category'] = 'article'
): ContextActions => ({
	type: ContextActionEnums.HANDLE_UPDATE_MODE,
	payload: {
		slug,
		category,
	},
})

export const handleCreateMode = (
	active: ICreateMode['active'],
	category: ICreateMode['category'] = 'article'
): ContextActions => ({
	type: ContextActionEnums.HANDLE_CREATE_MODE,
	payload: {
		active,
		category,
	},
})

export const handleModal = (options: IModal): ContextActions => ({
	type: ContextActionEnums.HANDLE_MODAL,
	payload: options,
})
