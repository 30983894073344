import { ChangeEvent } from 'react'

const useTagsBuilder = (
	tags: string[],
	type: 'add' | 'remove' | 'change',
	index = 0,
	event?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
): string[] => {
	const values = tags

	if (type === 'add') values.push('')
	else if (type === 'remove') values.splice(index, 1)
	else if (type === 'change') values[index] = event?.target?.value || ''

	return values
}

export default useTagsBuilder
