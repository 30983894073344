import { ReactElement, useCallback } from 'react'
import { Grid, MenuItem } from '@mui/material'
import { useAppContext } from 'hooks'
import { handleOptions } from 'context/actions'
import { Input, CreateButton } from 'components'

const categoryOptions = [
	{
		name: 'Блог',
		value: 'article',
	},
	{
		name: 'Наші послуги',
		value: 'our',
	},
	{
		name: 'Публічні послуги',
		value: 'public',
	},
	{
		name: 'ІнституціЇ',
		value: 'institution',
	},
	{
		name: 'Репозиторій',
		value: 'repository',
	},
]

const TableHead = (): ReactElement => {
	const {
		state: { tableOptions },
		dispatch,
	} = useAppContext()

	const handleChange = useCallback(
		value => {
			dispatch(
				handleOptions({
					...tableOptions,
					...value,
					page: 0,
				})
			)
		},
		[tableOptions]
	)

	return (
		<Grid container flexWrap='nowrap' justifyContent='space-between'>
			<Input
				select
				label='Категорія'
				value={tableOptions.category}
				onChange={e => handleChange({ category: e.target.value })}
			>
				{categoryOptions?.map(category => (
					<MenuItem key={category.value} value={category.value}>
						{category.name}
					</MenuItem>
				))}
			</Input>
			<CreateButton />
			<Input
				label='Пошук...'
				value={tableOptions?.search}
				onChange={e => handleChange({ search: e.target.value })}
			/>
		</Grid>
	)
}

export default TableHead
