import { ReactElement, Fragment, ChangeEvent, useMemo, useState } from 'react'
import { FormikProps, useFormik } from 'formik'
import { Container, Grid, Button, Box, IconButton } from '@mui/material'
import { AddRounded } from '@mui/icons-material'
import { Editor, Input, Loader } from 'components'
import { useTagsBuilder, useAppContext } from 'hooks'
import { DataTypes, ICreateData, IServerResponse } from 'types'
import {
	handleUpdateMode,
	handleCreateMode,
	handleNotification,
} from 'context/actions'
import { updateOne, createOne } from 'api'
import * as yup from 'yup'

const validationSchema = yup.object({
	title: yup
		.string()
		.max(250, 'Поле має бути не більше 250 символів')
		.required("Поле обов'язкове"),
	description: yup
		.string()
		.max(400, 'Поле має бути не більше 400 символів')
		.required("Поле обов'язкове"),
})

type DataType = DataTypes | ICreateData

interface IProps {
	data: DataType
	setData: (value: DataType | ((prev: DataType) => DataType)) => void
	id?: string
}

const DashboardForm = ({ data, setData, id }: IProps): ReactElement => {
	const [loading, setLoading] = useState(false)
	const {
		state: { updateMode, createMode },
		dispatch,
	} = useAppContext()

	const category = useMemo(
		() => (id ? updateMode.category : createMode.category),
		[id, updateMode.category, createMode.category]
	)

	const handleChange = (arg: {
		[key: string | 'article' | 'our' | 'public']: string | number | string[]
	}) => {
		setData(prev => ({
			...prev,
			...arg,
		}))
	}

	const changeTag = (
		type: 'add' | 'remove' | 'change',
		index?: number,
		event?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (data && 'tags' in data && data.tags) {
			const tagsArray = useTagsBuilder(data?.tags, type, index, event)
			handleChange({ tags: tagsArray })
		}
	}

	const formik: FormikProps<DataType> = useFormik<DataType>({
		initialValues: {
			...data,
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: async () => {
			setLoading(true)
			try {
				let response: IServerResponse
				const fetchCategory =
					category === 'our' || category === 'public' ? 'service' : category
				const fetchData = data
				if (fetchCategory === 'service' && 'words' in fetchData)
					delete fetchData.words
				else if (
					fetchCategory === 'institution' &&
					'words' in fetchData &&
					'tags' in fetchData
				) {
					delete fetchData.words
					delete fetchData.tags
				}
				const sendedData = ['our', 'public'].includes(category)
					? { ...fetchData, category }
					: fetchData
				if (id)
					response = await updateOne(
						fetchCategory,
						id,
						JSON.stringify(sendedData)
					)
				else
					response = await createOne(fetchCategory, JSON.stringify(sendedData))

				if (response?.status === 200) {
					dispatch(handleNotification(true, 'success', 'Успішно'))
					setData({
						title: '',
						description: '',
						content: '',
						tags: [''],
						words: 0,
					})
					dispatch(handleCreateMode(false))
					dispatch(handleUpdateMode(''))
				} else {
					dispatch(handleNotification(true, 'error', response?.message))
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				dispatch(handleNotification(true, 'error', error?.message))
			}
			setLoading(false)
		},
	})

	const handleBack = () => {
		dispatch(handleUpdateMode(''))
		dispatch(handleCreateMode(false))
	}

	return (
		<Fragment>
			{loading && <Loader />}
			<Container sx={{ m: 'auto', maxWidth: '1000px' }}>
				<Grid item mb='50px' sx={{ textAlign: 'end' }}>
					<Button onClick={handleBack}>Назад</Button>
				</Grid>
				<Box component='form' onSubmit={formik.handleSubmit}>
					<Grid container justifyContent='space-between' mb='30px'>
						<Input
							label='Назва'
							multiline
							rows={5}
							value={formik?.values?.title}
							onChange={e => {
								handleChange({ title: e.target.value })
								formik.handleChange(e)
							}}
							error={formik?.touched?.title && Boolean(formik.errors.title)}
							helperText={formik?.touched?.title && formik?.errors?.title}
							required
						/>
						<Input
							label='Опис'
							multiline
							rows={5}
							value={formik?.values?.description}
							onChange={e => {
								handleChange({ description: e.target.value })
								formik.handleChange(e)
							}}
							error={
								formik?.touched?.description &&
								Boolean(formik?.errors?.description)
							}
							helperText={
								formik?.touched?.description && formik?.errors?.description
							}
							required
						/>
					</Grid>
					<Editor
						value={data?.content}
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						handleEditorChange={(content: string, editor: any) =>
							handleChange({
								content,
								words: editor?.plugins?.wordcount.body?.getWordCount(),
							})
						}
					/>
					{category !== 'institution' && (
						<Grid container>
							<IconButton
								aria-label='add-tag'
								onClick={() => changeTag('add')}
								sx={{ mb: '20px' }}
							>
								<AddRounded />
							</IconButton>
							<Grid container justifyContent='space-between'>
								{data &&
									'tags' in data &&
									data.tags &&
									data?.tags.map((tag, idx) => (
										<Grid
											key={idx}
											container
											flexWrap='nowrap'
											minWidth='300px'
											width='fit-content'
											mb='20px'
										>
											<Input
												value={tag}
												label={`Тег ${idx + 1}`}
												onChange={e => changeTag('change', idx, e)}
												required
											/>
											<IconButton
												aria-label='remove-tag'
												onClick={() => changeTag('remove', idx)}
												sx={{ width: '56px' }}
											>
												<AddRounded sx={{ transform: 'rotate(45deg)' }} />
											</IconButton>
										</Grid>
									))}
							</Grid>
						</Grid>
					)}
					<Grid container mt='50px' justifyContent='flex-end'>
						<Button type='button' onClick={handleBack}>
							Скасувати
						</Button>
						<Button type='submit' sx={{ ml: '20px' }}>
							Зберегти
						</Button>
					</Grid>
				</Box>
			</Container>
		</Fragment>
	)
}

export default DashboardForm
