import { ReactElement, useCallback } from 'react'
import { TablePagination as Pagination } from '@mui/material'
import { useAppContext } from 'hooks'
import { handleOptions } from 'context/actions'

interface IProps {
	count: number
}

const TablePagination = ({ count }: IProps): ReactElement => {
	const {
		state: { tableOptions },
		dispatch,
	} = useAppContext()
	const { page, rowsPerPage } = tableOptions

	const handleChange = useCallback(
		value => {
			dispatch(
				handleOptions({
					...tableOptions,
					...value,
				})
			)
		},
		[tableOptions]
	)

	const handleChangePage = useCallback(
		(event, newPage) => {
			handleChange({ page: newPage })
		},
		[handleChange]
	)

	const handleChangeRowsPerPage = useCallback(
		event => {
			handleChange({ rowsPerPage: +event.target.value, page: 0 })
		},
		[handleChange]
	)

	return (
		<Pagination
			rowsPerPageOptions={[5, 10, 20]}
			component='div'
			count={count}
			rowsPerPage={rowsPerPage}
			labelRowsPerPage='Рядків/сторінку'
			page={page}
			onPageChange={handleChangePage}
			onRowsPerPageChange={handleChangeRowsPerPage}
			sx={{
				ml: 'auto',
			}}
		/>
	)
}

export default TablePagination
