import Cookies from 'js-cookie'
import { ApiParams, IRequestOptions, IServerResponse } from 'types'
import axios, { AxiosError, AxiosResponse } from 'axios'

enum ENDPOINTS {
	article = '/article',
	service = '/service',
	institution = '/institution',
	repository = '/repository',
	search = '/search',
	login = '/admin/login',
}

const request = <T>({
	options = {
		url: '/',
		method: 'get',
	},
	headers = {},
}: IRequestOptions<T>): IServerResponse | Promise<IServerResponse> => {
	const authToken = Cookies.get('authToken') || null
	const client = axios.create({
		baseURL: process.env.REACT_APP_API,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + authToken,
			...headers,
		},
		responseType: 'json',
	})

	const onSuccess = (response: AxiosResponse) => {
		return {
			...response.data,
			status: response.status,
		}
	}

	const onError = (error: AxiosError) => {
		const status: number = error?.response?.status || 400

		if ([401, 403].includes(status)) {
			Cookies.remove('authToken')
			window.location.href = '/'
		}

		return Promise.reject(error.response?.data?.message || error.message)
	}

	return client(options).then(onSuccess).catch(onError)
}

export default request

export const prepareUrl = (url: string, params: ApiParams): string => {
	// eslint-disable-next-line @typescript-eslint/no-extra-semi
	;(Object.keys(params) as Array<keyof typeof params>).forEach(
		// eslint-disable-next-line no-param-reassign
		key => (url += `${key.toString()}=${params[key]}&`)
	)

	return url
}

export const getMany = async <T>(
	endpoint: keyof typeof ENDPOINTS,
	params: ApiParams
): Promise<IServerResponse<T>> =>
	await request({
		options: {
			url: prepareUrl(ENDPOINTS[endpoint] + '?', params),
			method: 'get',
		},
	})

export const getOne = async <T>(
	endpoint: keyof typeof ENDPOINTS,
	slug: string
): Promise<IServerResponse<T>> =>
	await request({
		options: {
			url: ENDPOINTS[endpoint] + '/' + slug,
			method: 'get',
		},
	})

export const createOne = async (
	endpoint: keyof typeof ENDPOINTS,
	body: string
): Promise<IServerResponse> =>
	await request({
		options: {
			url: ENDPOINTS[endpoint],
			method: 'post',
			data: body,
		},
	})

export const updateOne = async (
	endpoint: keyof typeof ENDPOINTS,
	slug: string,
	body: string
): Promise<IServerResponse> =>
	await request({
		options: {
			url: ENDPOINTS[endpoint] + '/' + slug,
			method: 'put',
			data: body,
		},
	})

export const deleteOne = async (
	endpoint: keyof typeof ENDPOINTS,
	slug: string
): Promise<IServerResponse> =>
	await request({
		options: {
			url: ENDPOINTS[endpoint] + '/' + slug,
			method: 'delete',
		},
	})
