/* eslint-disable no-tabs */
import { PaletteMode, Theme } from '@mui/material'
import { createTheme } from '@mui/material/styles'

export const colors = {
	light: {
		primary: '#F0F4F6',
		secondary: '#FFFFFF',
		info: '#5D5D5D',
		textPrimary: '#3D3D3D',
		textSecondary: '#5D5D5D',
		actionHover: '#FCF113',
		commonBlack: '#000000',
	},
	dark: {
		primary: '#5D5D5D',
		secondary: '#3D3D3D',
		info: '#F0F4F6',
		textPrimary: '#F0F4F6',
		textSecondary: '#DCE7EC',
		actionHover: '#FCF113',
		commonBlack: '#FFFFFF',
	},
}

const theme = (mode: PaletteMode = 'light'): Theme =>
	createTheme({
		palette: {
			mode,
			primary: {
				main: colors[mode].primary,
			},
			secondary: {
				main: colors[mode].secondary,
			},
			info: {
				main: colors[mode].info,
			},
		},
		typography: {
			fontFamily: 'Merriweather',
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					body: {
						WebkitTapHighlightColor: 'transparent',
						backgroundColor: colors[mode].primary,
					},
				},
			},
			MuiTextField: {
				defaultProps: {
					variant: 'outlined',
					fullWidth: true,
					color: 'info',
					autoComplete: 'off',
				},
				styleOverrides: {
					root: {
						minWidth: '300px',
						maxWidth: '400px',
					},
				},
			},
			MuiButton: {
				defaultProps: {
					color: 'info',
				},
				styleOverrides: {
					root: {
						backgroundColor: colors[mode].primary,
					},
				},
			},
			MuiSpeedDialAction: {
				styleOverrides: {
					staticTooltip: {
						marginBottom: '20px',
					},
					staticTooltipLabel: {
						whiteSpace: 'nowrap',
					},
				},
			},
		},
	})

export default theme
