import { ReactElement, Fragment, useMemo } from 'react'
import { Container } from '@mui/material'
import { TableWrapper, UpdateSection, CreateSection } from 'components'
import { useAppContext } from 'hooks'

const Dashboard = (): ReactElement => {
	const {
		state: {
			tableOptions: { category },
			updateMode: { slug },
		},
	} = useAppContext()

	const currentCategory = useMemo(
		() =>
			category === 'article' ||
			category === 'institution' ||
			category === 'repository'
				? category
				: 'service',
		[category]
	)

	return (
		<Fragment>
			<Container sx={{ pt: '20px', pb: '40px' }}>
				<CreateSection />
				<TableWrapper currentCategory={currentCategory} />
				{slug && <UpdateSection currentCategory={currentCategory} />}
			</Container>
		</Fragment>
	)
}

export default Dashboard
