import { ReactElement, Fragment, useEffect, useState } from 'react'
import { Collapse, Paper } from '@mui/material'
import { useAppContext, useFetch } from 'hooks'
import { Categories4Fetch, DataTypes, ICreateData, IRepository } from 'types'
import { getOne } from 'api'
import { Loader, DataForm } from 'components'
import { handleNotification } from 'context/actions'
import Form from 'components/Repository/components/Form'

interface IProps {
	currentCategory: Categories4Fetch
}

const UpdateSection = ({ currentCategory }: IProps): ReactElement => {
	const [data, setData] = useState<DataTypes | ICreateData>({} as DataTypes)
	const {
		state: {
			tableOptions: { category },
			updateMode: { slug },
		},
		dispatch,
	} = useAppContext()

	const {
		loading,
		data: { rows: row },
		error,
	} = useFetch(
		async () => await getOne<DataTypes>(currentCategory, slug),
		[currentCategory, category, slug]
	)

	useEffect(() => {
		if (row)
			setData({
				title: row?.title,
				description: row?.description,
				content: row?.content,
				tags: 'tags' in row ? row?.tags : [''],
			})
	}, [row])

	useEffect(() => {
		if (error) dispatch(handleNotification(true, 'error', error))
	}, [error, dispatch])

	return (
		<Fragment>
			{loading && <Loader />}
			<Collapse in={!!slug} timeout='auto' unmountOnExit>
				<Paper sx={{ p: '24px' }}>
					{row?._id ? (
						category !== 'repository' ? (
							<DataForm data={data} setData={setData} id={row?._id} />
						) : (
							<Form data={row as unknown as IRepository} id={row._id} />
						)
					) : null}
				</Paper>
			</Collapse>
		</Fragment>
	)
}

export default UpdateSection
