import { StrictMode, Fragment } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ContextProvider } from './context'
import { Notification, Modal } from 'components'
import Theme from 'styles/theme'
import 'styles/fonts/font.css'

ReactDOM.render(
	<StrictMode>
		<ContextProvider>
			<Theme>
				<Fragment>
					<Notification />
					<Modal />
					<App />
				</Fragment>
			</Theme>
		</ContextProvider>
	</StrictMode>,
	document.getElementById('root')
)

reportWebVitals()
