import { Dispatch } from 'react'

export type Categories =
	| 'article'
	| 'our'
	| 'public'
	| 'institution'
	| 'repository'

export interface INotification {
	open: boolean
	variant: 'success' | 'error' | 'warning' | 'info'
	message: string
}

export type ThemeType = 'light' | 'dark'

export interface ITableOptions {
	category: Categories
	rowsPerPage: number
	page: number
	search: string
}

export interface IModal {
	open: boolean
	id: string
	category: Categories
}

export interface IUpdateMode {
	slug: string
	category: Categories
}

export interface ICreateMode {
	active: boolean
	category: Categories
}

export interface IAppState {
	notification: INotification
	themeMode: ThemeType
	tableOptions: ITableOptions
	updateMode: IUpdateMode
	createMode: ICreateMode
	modal: IModal
}

export enum ContextActionEnums {
	HANDLE_NOTIFICATION = 'HANDLE_NOTIFICATION',
	HANDLE_THEME = 'HANDLE_THEME',
	HANDLE_TABLE_OPTIONS = 'HANDLE_TABLE_OPTIONS',
	HANDLE_UPDATE_MODE = 'HANDLE_UPDATE_MODE',
	HANDLE_CREATE_MODE = 'HANDLE_CREATE_MODE',
	HANDLE_MODAL = 'HANDLE_MODAL',
}

interface ChangeNotification {
	type: ContextActionEnums.HANDLE_NOTIFICATION
	payload: INotification
}

interface ChangeTheme {
	type: ContextActionEnums.HANDLE_THEME
	payload: ThemeType
}

interface ChangeTableOptions {
	type: ContextActionEnums.HANDLE_TABLE_OPTIONS
	payload: ITableOptions
}

interface SetUpdateSlug {
	type: ContextActionEnums.HANDLE_UPDATE_MODE
	payload: IUpdateMode
}

interface HandleCreateMode {
	type: ContextActionEnums.HANDLE_CREATE_MODE
	payload: ICreateMode
}

interface ChangeModal {
	type: ContextActionEnums.HANDLE_MODAL
	payload: IModal
}

export type ContextActions =
	| ChangeNotification
	| ChangeTheme
	| ChangeTableOptions
	| SetUpdateSlug
	| HandleCreateMode
	| ChangeModal

export interface IAppContext {
	state: IAppState
	dispatch: Dispatch<ContextActions>
}
