import { ReactElement } from 'react'
import { Grid, Box } from '@mui/material'
import { Login } from 'components'

const Home = (): ReactElement => (
	<Grid item height='calc(100vh - 180px)'>
		<Box
			width='100%'
			height='calc(100% - 180px)'
			position='absolute'
			zIndex={-1}
			component='img'
			src='/landing.jpg'
			alt='landing'
		/>
		<Grid
			container
			p='24px'
			height='100%'
			flexDirection='column'
			alignItems='center'
			justifyContent='center'
		>
			<Login />
		</Grid>
	</Grid>
)

export default Home
