import { ReactElement, useEffect, Fragment, useMemo } from 'react'
import { Grid, Paper, Collapse } from '@mui/material'
import { Loader, TableHead, TablePagination, TableContent } from 'components'
import { useAppContext, useFetch } from 'hooks'
import {
	ApiParams,
	Categories4Fetch,
	DataTypes,
	ICreateRepository,
} from 'types'
import { getMany } from 'api'
import { handleNotification } from 'context/actions'
import List from 'components/Repository/components/List'

interface IProps {
	currentCategory: Categories4Fetch
}

const TableWrapper = ({ currentCategory }: IProps): ReactElement => {
	const {
		state: {
			tableOptions: { category, page, rowsPerPage, search },
			updateMode: { slug },
			createMode: { active },
			modal: { id },
		},
		dispatch,
	} = useAppContext()

	const fetchConfig = useMemo(() => {
		const config: ApiParams = {
			limit: rowsPerPage,
			page: page + 1,
			q: search,
		}
		if (category === 'our' || category === 'public') config.category = category

		return config
	}, [category, rowsPerPage, page, search])

	const {
		loading,
		data: { rows = [], count = 0 },
		error,
	} = useFetch(
		async () => await getMany<DataTypes[]>(currentCategory, fetchConfig),
		[currentCategory, category, rowsPerPage, page, active, slug, id, search]
	)

	useEffect(() => {
		if (error) dispatch(handleNotification(true, 'error', error))
	}, [error, dispatch])

	const isOpenTable = useMemo(() => {
		if (slug || active) return false
		else return true
	}, [slug, active])

	return (
		<Fragment>
			{loading && <Loader />}
			<Collapse in={isOpenTable} timeout='auto' unmountOnExit>
				<Paper sx={{ p: '24px' }}>
					<Grid container>
						<TableHead />
						{currentCategory === 'repository' ? (
							<List rows={rows as unknown as ICreateRepository[]} />
						) : (
							<TableContent rows={rows} />
						)}
						<TablePagination count={count} />
					</Grid>
				</Paper>
			</Collapse>
		</Fragment>
	)
}

export default TableWrapper
