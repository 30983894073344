import { useState, useCallback, useEffect } from 'react'
import { IServerResponse } from 'types'

interface ReturnType<T> {
	loading: boolean
	data: IServerResponse<T>['data']
	error: string
}

const useFetch = <T>(
	fn: <V extends Array<string>>(
		...args: V
	) => Promise<IServerResponse<T> | undefined>,
	deps: Array<string | number | boolean>
): ReturnType<T> => {
	const [data, setData] = useState<IServerResponse['data']>({
		rows: [],
		count: 0,
	})
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState('')

	const fetch = useCallback(
		async (...args) => {
			setLoading(true)
			try {
				const response = await fn(...args)

				if (response?.status === 200)
					setData({
						rows: response?.data?.rows || [],
						count: response?.data?.count || 0,
					})
				else setError(response?.message || '')
			} catch (error) {
				setError(error as string)
			}
			setLoading(false)
		},
		[...deps]
	)

	useEffect(() => {
		fetch()
	}, [fetch, ...deps])

	return { loading, data, error }
}

export default useFetch
